// Reports routes.........
export const userListByDesignationApi = 'user/users-by-designation-id'

const report = '/reports/'
export const bilateralTradeReport = report + 'bilateral-trade-report'
export const exportDestinationsReport = report + 'export-destinations-report'
export const exporterSpecificCountryReport = report + 'exporter-specific-country-report'
export const importerSpecificCountryReport = report + 'importer-specific-country-report'
export const importDestinationsReport = report + 'import-destinations-report'
export const countryInformationManagementReportList = report + 'country-info-report/list'
export const countryInformationManagementReportPdf = report + 'country-info-report/pdf'
export const AssignApplicationStatusReportList = report + 'assign-application-status-report/list'
export const AssignApplicationStatusReportPdf = report + 'assign-application-status-report/pdf'
// application report
export const applicationReportList = report + 'application-report/list'
export const applicationReportPdf = report + 'application-report/pdf'
export const tariffPortsLstApi = report + 'country-info-report/ports'

// application report
export const concernPersonReportList = report + 'concern-person-report/list'
export const concernPersonReportPdf = report + 'concern-person-report/pdf'
export const concernPersonReportApi = report + 'concern-person-report/ports'
// import Export Potential
export const importExportPotentialReportList = report + 'import-export-potential-report/list'

// Import Products By Specific Country Report
export const importProductsSpecificCountryReportList = report + 'import-products-specific-country-report/list'
// export product to a specific country
export const exportProductSpecificCountryReportList = report + 'export-product-specific-country-report/list'
