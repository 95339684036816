<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('tradeTariffReport.potential_report') }} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
          <b-row>
            <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Country" vid="country_id" rules="" v-slot="{ errors }">
                <b-form-group
                  label-for="country_id"
                >
                <template v-slot:label>
                  {{ $t('globalTrans.country_name')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.country_id"
                  :options="tradeTariffCountryList"
                  id="country_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="" v-slot="{ errors }">
                <b-form-group
                  label-for="fiscal_year_id"
                >
                <template v-slot:label>
                  {{ $t('globalTrans.fiscal_year')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.fiscal_year_id"
                  :options="fiscalYearList"
                  id="fiscal_year_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          <b-col sm="9">
          </b-col>
          <b-col sm="3" style="text-align: right;">
            <b-button size="sm" type="submit" variant="primary" class="mt-20">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
        </b-form>
      </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>
    <!-- <b-overlay :show="unitLoading">
    </b-overlay> -->
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title text-left">{{ $t('tradeTariffReport.potential_report') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="unitLoading">
        <ReportHeading/>
        <b-row class="text-right mt-2">
            <b-col>
              <b-button @click="pdfExport" class="btn btn-success btn-md">
                <i class="ri-file-pdf-line"></i>  {{  $t('globalTrans.pdf') }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="mt-3">
                    <table style="line-height: 25px" class="table table-sm table-bordered">
                      <tr>
                          <th>{{ $t('globalTrans.sl_no')}} </th>
                          <th>{{ $t('tradeTariffApp.hs_code')}} </th>
                          <th>{{ $t('tradeTariffApp.product_name')}} </th>
                          <th>{{ $t('tradeTariffApp.export_amount')}} </th>
                          <th>{{ $t('tradeTariffApp.import_amount')}} </th>
                      </tr>
                      <tr  v-for="(data,index) in export_import" :key="index">
                          <td>{{ $n(index + 1, { useGrouping: false }) }} </td>
                          <td>{{ data.hs_code }} </td>
                          <td>{{ data.product_name_en }} </td>
                          <td class="text-right">{{ $n(data.export_amount, { useGrouping: false }) }} </td>
                          <td class="text-right">{{ $n(data.import_amount, { useGrouping: false }) }} </td>
                      </tr>
                    </table>
            </b-col>
          </b-row>
        </b-overlay>
        </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import ReportHeading from '../../pages/report-heading/ReportHeading.vue'
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { importExportPotentialReportList } from '../../api/routes'

export default {
  components: {
    ReportHeading
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      ports: [],
      seaPortsList: [],
      landPortsList: [],
      airPortsList: [],
      top_exporters: [],
      top_importers: [],
      current_signatories: [],
      mfn: '',
      wto_agri: '',
      wto_indus: '',
      export_import: [],
      unitLoading: false,
      search: {
        country_id: 0,
        fiscal_year_id: 0,
        start_date: '',
        end_date: '',
        limit: 10
      }
    }
  },
  created () {
    this.getPortsList()
  },
  watch: {
    'search.country_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
      } else {
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    tradeTariffCountryList: function () {
      return this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.filter(item => item.status === 1)
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  methods: {
    getCountryName (id) {
        const obj = this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.find(item => item.value === parseInt(id))
        if (obj) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
        } else {
            return ''
        }
    },
    searchData () {
      this.unitLoading = true
      this.loadData()
    },
    async loadData () {
      if (this.search.fiscal_year_id) {
        this.search.start_date = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id).start_date
        this.search.end_date = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id).end_date
      }
      const params = Object.assign({}, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, importExportPotentialReportList, params)
      if (result.success) {
        this.unitLoading = false
        this.export_import = result.export_import
      } else {
        this.unitLoading = false
        this.$store.dispatch('setList', [])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async pdfExport () {
        this.unitLoading = true
        this.search.potential = true
        const params = Object.assign({}, this.search, { request_type: 'pdf', local: this.$i18n.locale, org_id: 7 })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(tradeTariffServiceBaseUrl, importExportPotentialReportList, params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        this.unitLoading = false
    }
  }
}
</script>
